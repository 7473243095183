/* Mobile Styles */
@media screen and (max-width: 600px) {
  .slick-slider {
    max-width: 80%; /* Adjust the width as needed */
    margin: 0 auto;
  }

  .slick-slide img {
    max-width: 100%;
    height: auto;
  }
}

/* Desktop Styles (Optional) */
@media screen and (min-width: 601px) {
  .slick-slider {
    max-width: 100%; /* Adjust the width as needed */
  }
}



.slick-slider {
  max-width: 80%; 
  margin: 0 auto;
  height: 300px; 
}

/* Set the height of the slides */
.slick-slide img {
  max-width: 100%;
  object-fit: cover; 
}
