.campaign-card {
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
}

.card-front {
  position: relative;
}

.card-image {
  width: 100%;
}

.card-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 8px;
  border-radius: 8px;
}

.card-footer {
  background-color: #f0f0f0;
  padding: 8px;
  text-align: center;
}

@media (max-width: 576px) {
  .card-data {
    width: 100%;
  }
}
