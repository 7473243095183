.signup-container {
  background: linear-gradient(to right, #85D8CE, #007BA7);
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.required {
  color: red;
}
.signintxt{
    color: rgb(64, 0, 255);
    margin-left: 20px;
    font-size: 18px;
    text-decoration: underline;
}
a{
    color: white;
}